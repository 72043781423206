/* global rp$, window, grecaptcha */

var THE_SCRIPT = function () {

	const $onReady = require('public/js/lib/on-ready');

	const newMemberContactUsFormId                = '#new-member-contact-us-form';
	const newMemberContactUsFormSubmitButtonClass = '.new-member-contact-us-form-submit';

	window.getNewMemberContactUsFormCaptchaToken = function() {
		newMemberContactUsFormSubmit();
	}

	function newMemberContactUsFormSubmit() {
		rp$(newMemberContactUsFormId).submit();
	}

	$onReady({
		rp$,
		window,
		label: 'new_member_contact_us_form',
		fn: function () {
			rp$(newMemberContactUsFormSubmitButtonClass).on('click', function (evt) {
				evt.preventDefault();
				if (rp$(this).hasClass('disabled')) {
					return;
				}

				if (typeof grecaptcha === 'object') {
					let captchaIndex = -1;
					rp$('.g-recaptcha').each(function(index) {
						if (rp$(this)[0].id === 'new-member-contact-us-form-captcha') {
							captchaIndex = index;
						}
					});
					grecaptcha.execute(captchaIndex);
				} else {
					newMemberContactUsFormSubmit();
				}
			});
		},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
